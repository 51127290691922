export const ContactNames = [{
        initial: "#",
        name: "100",
    },
    {
        initial: "",
        name: "101",
    },
    {
        initial: "",
        name: "102",
    },
    {
        initial: "",
        name: "1091",
    },
    {
        initial: "",
        name: "112",
    },
    {
        initial: "",
        name: "181",
    },
    {
        initial: "A",
        name: "Adolf Hitler",
    },
    {
        initial: "",
        name: "Anonymuous",
    },
    {
        initial: "B",
        name: "Barack Obama",
    },
    {
        initial: "",
        name: "Bhupendra Sir",
    },
    {
        initial: "D",
        name: "Donald Trump",
    },
    {
        initial: "K",
        name: "Kim Jong",
    },
    {
        initial: "P",
        name: "PM Sir",
    },
    {
        initial: "Y",
        name: "Yogi Ji",
    },

];