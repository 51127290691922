import React from "react";
import "./Recents.css";
import RecentsCall from "./RecentsCall/RecentsCall";
import { HiOutlinePhoneIncoming, HiOutlinePhoneOutgoing } from "react-icons/hi";
import { MdOutlinePhoneMissed } from "react-icons/md";

function Recents() {
  let day = new Date();
  let hrs = day.getHours();

  return (
    <>
      <div className="recentsCont">
        <RecentsCall
          recentDay="Today"
          recentIcon={<HiOutlinePhoneIncoming size="1em" color="#008B8B" />}
          recentName="Adolf Hitler"
          recentTime={hrs + ":00"}
        />
        <RecentsCall
          recentIcon={<MdOutlinePhoneMissed size="1em" color="#FF0000" />}
          recentName="Adolf Hitler"
          recentNumber="(15)"
          recentTime={hrs - 1 + ":45"}
        />
        <RecentsCall
          recentIcon={<MdOutlinePhoneMissed size="1em" color="#FF0000" />}
          recentName="Adolf Hitler"
          recentNumber="(15)"
          recentTime={hrs - 1 + ":30"}
        />
        <RecentsCall
          recentIcon={<HiOutlinePhoneOutgoing size="1em" color="#00FF00" />}
          recentName="Adolf Hitler"
          recentTime={hrs - 1 + ":15"}
        />
        <RecentsCall
          recentIcon={<HiOutlinePhoneOutgoing size="1em" color="#00FF00" />}
          recentName="PM Sir"
          recentTime={hrs - 2 + ":00"}
        />
        <RecentsCall
          recentIcon={<HiOutlinePhoneOutgoing size="1em" color="#00FF00" />}
          recentName="PM Sir"
          recentTime={hrs - 3 + ":00"}
        />
        <RecentsCall
          recentIcon={<HiOutlinePhoneOutgoing size="1em" color="#00FF00" />}
          recentName="PM Sir"
          recentTime={hrs - 4 + ":00"}
        />
        <RecentsCall
          recentIcon={<HiOutlinePhoneIncoming size="1em" color="#008B8B" />}
          recentName="Donald Trump"
          recentTime={hrs - 5 + ":45"}
        />
        <RecentsCall
          recentIcon={<HiOutlinePhoneIncoming size="1em" color="#008B8B" />}
          recentName="Anonymuos"
          recentTime={hrs - 6 + ":15"}
        />
        <RecentsCall
          recentDay="Yesterday"
          recentIcon={<HiOutlinePhoneOutgoing size="1em" color="#00FF00" />}
          recentName="Yogi Ji"
          recentNumber="(3)"
          recentTime={hrs - 1 + ":45"}
        />
      </div>
    </>
  );
}

export default Recents;
