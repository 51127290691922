import React, { useState } from "react";
import "./Contacts.css";
import ContactsRaw from "./ContactsRaw/ContactsRaw";
import { ContactNames } from "../Contacts/ContactNames";

function Contacts() {
  const [clicked, setClicked] = useState("0");
  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };
  return (
    <>
      <div className="contactsCont">
        {ContactNames.map((name, index) => (
          <ContactsRaw
            onToggle={() => handleToggle(index)}
            active={clicked === index}
            key={index}
            names={name}
          />
        ))}
      </div>
    </>
  );
}

export default Contacts;
