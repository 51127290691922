import React from "react";
import AccordionSlide2 from "../AccordionSlide2/AccordionSlide2";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import "@coreui/coreui/dist/css/coreui.min.css";
import { CAccordion } from "@coreui/react";
import { FcAlarmClock, FcDisplay } from "react-icons/fc";
import "../Slide2.css";
function Clocks() {
  return (
    <div>
      <div className="projectsCont clockCont">
        <div className="projectsHeader clockHeader">
          <Link to="/">
            <IoIosArrowBack size={"1.8em"} color="#0000FF" variant="Broken" />
          </Link>
          <h1>Clocks</h1>
        </div>
        <div className="projectsBody clockBody">
          <CAccordion>
            <AccordionSlide2
              itemKey={1}
              name="Analoge Clock"
              icon={<FcAlarmClock size={"2em"} />}
              demolink="https://vats1812.github.io/vatspatel/analoge%20clock/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/analoge%20clock"
            />
            <AccordionSlide2
              itemKey={2}
              name="Digital Clock"
              icon={<FcDisplay size={"2em"} />}
              demolink="https://vats1812.github.io/vatspatel/clock/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/clock"
            />
          </CAccordion>
        </div>
      </div>
    </div>
  );
}

export default Clocks;
