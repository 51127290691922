import React from "react";
import "./PhoneBody.css";
import notch from "../../assets/notch.jpg";
import PhoneAppBody from "../PhoneAppBody/PhoneAppBody";

function PhoneBody() {
  return (
    <div>
      <div className="bodyContainer">
        <div className="border1">
          
          <div className="notch">
            <img src={notch} alt="Notch" />
          </div>
          <PhoneAppBody />
        </div>
      </div>
    </div>
  );
}

export default PhoneBody;
