import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { CAccordion } from "@coreui/react";
import { FcCalculator, FcCalendar, FcTodoList } from "react-icons/fc";
import { BsKeyboardFill } from "react-icons/bs";
import AccordionSlide2 from "../AccordionSlide2/AccordionSlide2";
import "../Slide2.css";

function Tools() {
  return (
    <div>
      <div className="projectsCont toolsCont">
        <div className="projectsHeader toolsHeader">
          <Link to="/">
            <IoIosArrowBack size={"1.8em"} color="#0000FF" variant="Broken" />
          </Link>
          <h1> Tools</h1>
        </div>
        <div className="projectsBody toolsBody">
          <CAccordion>
            <AccordionSlide2
              itemKey={1}
              name="Calculator"
              icon={<FcCalculator size={"2em"} />}
              demolink="https://vats1812.github.io/vatspatel/calculator/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/calculator"
            />
            <AccordionSlide2
              itemKey={2}
              name="Calender"
              icon={<FcCalendar size={"2em"} />}
              demolink="https://vats1812.github.io/vatspatel/calender/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/calender"
            />
            <AccordionSlide2
              itemKey={3}
              name="TO-DO List"
              icon={<FcTodoList size={"2em"} />}
              demolink="https://vats1812.github.io/vatspatel/todolist/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/todolist"
            />
            <AccordionSlide2
              itemKey={4}
              name="Keyboard"
              icon={<BsKeyboardFill color="#000" size={"2em"} />}
              demolink="https://vats1812.github.io/vatspatel/keyboard/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/keyboard"
            />
          </CAccordion>
        </div>
      </div>
    </div>
  );
}

export default Tools;
