import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import "@coreui/coreui/dist/css/coreui.min.css";
import { CAccordion } from "@coreui/react";
import { GiClick, GiTicTacToe, GiThink } from "react-icons/gi";
import "../Slide2.css";
import AccordionSlide2 from "../AccordionSlide2/AccordionSlide2";

function Games() {
  return (
    <div>
      <div className="projectsCont gamesCont">
        <div className="projectsHeader gamesHeader">
          <Link to="/">
            <IoIosArrowBack size={"1.8em"} color="#0000FF" variant="Broken" />
          </Link>
          <h1> Games</h1>
        </div>
        <div className="projectsBody gamesBody">
          <CAccordion>
            <AccordionSlide2
              itemKey={1}
              name="TIC TAC TOE"
              icon={<GiTicTacToe size={"1.5em"} />}
              demolink="https://vats1812.github.io/vatspatel/tic-tac/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/tic-tac"
            />
            <AccordionSlide2
              itemKey={2}
              name="Memory Puzzle 4*4"
              icon={<GiThink color="#FFBEAC" size={"1.5em"} />}
              demolink="https://vats1812.github.io/vatspatel/puzzle1/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/puzzle1"
            />
            <AccordionSlide2
              itemKey={3}
              name="Memory Puzzle 6*6"
              icon={<GiThink color="#FFBEAC" size={"1.5em"} />}
              demolink="https://vats1812.github.io/vatspatel/puzzle2/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/puzzle2"
            />
            <AccordionSlide2
              itemKey={4}
              name="Click Game"
              icon={<GiClick size={"1.5em"} />}
              demolink="https://vats1812.github.io/vatspatel/Click%20Game/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/Click%20Game"
            />
          </CAccordion>
        </div>
      </div>
    </div>
  );
}

export default Games;
