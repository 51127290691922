import React from "react";
import { IoIosArrowBack, IoMdSwitch } from "react-icons/io";
import { IoLogIn } from "react-icons/io5";
import { Link } from "react-router-dom";
import { CAccordion } from "@coreui/react";
import { BsEmojiSmileFill } from "react-icons/bs";
import { FaCat } from "react-icons/fa";
import AccordionSlide2 from "../AccordionSlide2/AccordionSlide2";
import "../Slide2.css";

function Javascript() {
  return (
    <div>
      <div className="projectsCont javaCont">
        <div className="projectsHeader javaHeader">
          <Link to="/">
            <IoIosArrowBack size={"1.8em"} color="#0000FF" variant="Broken" />
          </Link>
          <h1>JavaScript Projects</h1>
        </div>
        <div className="projectsBody javaBody">
          <CAccordion>
            <AccordionSlide2
              itemKey={1}
              name="Watching Cat"
              icon={<FaCat color="black" size={"1.3em"} />}
              demolink="https://vats1812.github.io/vatspatel/cat/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/cat"
            />
            <AccordionSlide2
              itemKey={2}
              name="Animated Login"
              icon={<IoLogIn color="green" size={"1.3em"} />}
              demolink="https://vats1812.github.io/vatspatel/login/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/login"
            />
            <AccordionSlide2
              itemKey={3}
              name="Smiley Devil"
              icon={<BsEmojiSmileFill color="purple" size={"1.3em"} />}
              demolink="https://vats1812.github.io/vatspatel/smiley/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/smiley"
            />
            <AccordionSlide2
              itemKey={4}
              name="Happy Switching"
              icon={<IoMdSwitch color="ffacb7" size={"1.5em"} />}
              demolink="https://vats1812.github.io/vatspatel/switch/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/switch"
            />
          </CAccordion>
        </div>
      </div>
    </div>
  );
}

export default Javascript;
