import React from "react";
import CallFooter from "./CallFooter/CallFooter";
import "./Call.css";
import CallHeader from "./CallHeader/CallHeader";
import CallBody from "./CallBody/CallBody";

function Call() {
  return (
    <div>
      <div className="callContainer">
        <CallHeader />
        <CallBody />
        <CallFooter />
      </div>
    </div>
  );
}

export default Call;
