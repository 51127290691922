import React, { useState, useRef } from "react";
import { FiSend } from "react-icons/fi";
import "./MessageForm.css";
import validator from "validator";
import emailjs from "emailjs-com";

function MessageForm() {
  const forms = useRef();
  const serviceid = process.env.REACT_APP_SSID;
  const tempid = process.env.REACT_APP_TEMPID;
  const usid = process.env.REACT_APP_USID;

  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    const addNote = () => {
      emailjs.sendForm(serviceid, tempid, forms.current, usid).then();
    };
    if (form.name.length < 5) {
      alert("Name can't have 5 letters only");
    } else if (form.name.length > 15) {
      alert("Name can't have more than 15 letters");
    } else if (!validator.isEmail(form.email)) {
      alert("Enter a valid Email");
    } else if (form.subject.length < 10) {
      alert("Subject can't have less than 10 letters");
    } else if (form.message.length < 30) {
      alert("Message can't have less than 30 letters");
    } else {
      addNote();

      setForm({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      alert("Message Sent");
    }
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <form className="formCont" ref={forms}>
        <div className="formGroup">
          <input
            type="input"
            className="formField"
            placeholder="Name"
            name="name"
            value={form.name}
            onChange={onChange}
            required
          />
          <label htmlFor="name" className="formLabel">
            Your Name
          </label>
        </div>
        <div className="formGroup">
          <input
            type="email"
            className="formField"
            placeholder="Email"
            name="email"
            value={form.email}
            onChange={onChange}
            required
          />
          <label htmlFor="name" className="formLabel">
            Your Email
          </label>
        </div>
        <div className="formGroup">
          <input
            type="input"
            className="formField"
            placeholder="Subject"
            name="subject"
            value={form.subject}
            onChange={onChange}
            required
          />
          <label htmlFor="name" className="formLabel">
            Subject
          </label>
        </div>
        <div className="formGroup">
          <input
            type="input"
            className="formField"
            placeholder="Message"
            name="message"
            value={form.message}
            onChange={onChange}
            required
          />
          <label htmlFor="name" className="formLabel">
            Your Message
          </label>
        </div>
        <div className="formSubmit">
          <button
            className="button submit"
            type="submit"
            onClick={handleSubmit}
          >
            <span>Send</span>
            <FiSend color="006400" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default MessageForm;
