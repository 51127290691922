import React from "react";
import { Link } from "react-router-dom";
import { CAccordion } from "@coreui/react";
import { BsCurrencyBitcoin, BsChatSquareQuoteFill } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { FcIphone } from "react-icons/fc";
import { GiWorld, GiBookAura } from "react-icons/gi";
import { MdOnlinePrediction } from "react-icons/md";
import { FaRobot } from "react-icons/fa";
import "../Slide2.css";
import AccordionSlide2 from "../AccordionSlide2/AccordionSlide2";

function ReactProj() {
  return (
    <div>
      <div className="projectsCont reactCont">
        <div className="projectsHeader reactHeader">
          <Link to="/">
            <IoIosArrowBack size={"1.8em"} color="#0000FF" variant="Broken" />
          </Link>
          <h1> React.js Projects </h1>
        </div>
        <div className="projectsBody reactBody">
          <CAccordion>
            <AccordionSlide2
              itemKey={1}
              name="Famogram"
              icon={<GiWorld color="teal" size={"1.5em"} />}
              demolink="https://vats1812.github.io/Famogram/"
            />
            <AccordionSlide2
              itemKey={2}
              name="3 D Phone"
              icon={<FcIphone size={"1.5em"} />}
              demolink="http://3dphone.vatspatel.in/"
            />
            <AccordionSlide2
              itemKey={3}
              name="Crypto Tracker"
              icon={<BsCurrencyBitcoin size={"1.5em"} />}
              demolink="http://crypto.vatspatel.in/"
              codelink="https://github.com/vats1812/crypto-rate-tracker"
            />
            <AccordionSlide2
              itemKey={4}
              name="Predictor"
              icon={<MdOnlinePrediction color="#FFD43B" size={"1.5em"} />}
              demolink="http://predictor.vatspatel.in/"
              codelink="https://github.com/vats1812/age-gender-counrty-predictor"
            />
            <AccordionSlide2
              itemKey={5}
              name="Quote Generator"
              icon={<BsChatSquareQuoteFill color="#00FFFF" size={"1.3em"} />}
              demolink="http://quotes.vatspatel.in/"
              codelink="https://github.com/vats1812/quote-generator"
            />
            <AccordionSlide2
              itemKey={6}
              name="Robogram"
              icon={<FaRobot color="#C8C8C8" size={"1.5em"} />}
              demolink="http://robots.vatspatel.in/"
              codelink="https://github.com/vats1812/robogram"
            />
            <AccordionSlide2
              itemKey={7}
              name="Bhagvad Gita"
              icon={<GiBookAura color="#ff8000" size={"1.5em"} />}
              demolink="http://bhagvadgita.vatspatel.in/"
              codelink="https://github.com/vats1812/bhagavad-gita"
            />
          </CAccordion>
        </div>
      </div>
    </div>
  );
}

export default ReactProj;
