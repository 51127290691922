import React from "react";
import "./Footer.css";
import Apps from "../Apps/Apps";
import { Link } from "react-router-dom";
import { RiSettings5Fill } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { BsChatTextFill } from "react-icons/bs";
import { MdPhone } from "react-icons/md";

function Footer() {
  return (
    <>
      <div className="footerContainer">
        <Link to="/call/keypad">
          <Apps
            icon={<MdPhone size={"2em"} color="#37d67a" variant="Broken" />}
          />
        </Link>
        <Link to="/message">
          <Apps icon={<BsChatTextFill size={"2em"} color="#2ccce4" />} />
        </Link>
        <a
          href="https://wa.me/918141586514"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apps icon={<FaWhatsapp size={"2em"} color="#05d60f" />} />
        </a>
        <Apps
          icon={
            <RiSettings5Fill size={"2em"} color="#d9e3f0" variant="Broken" />
          }
        />
      </div>
    </>
  );
}

export default Footer;
