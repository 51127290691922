import React from "react";
import Apps from "../../Apps/Apps";
import playstore from "../../../assets/icons/playstore.svg";
import drive from "../../../assets/icons/drive.svg";
import gmail from "../../../assets/icons/gmail.svg";
import instagram from "../../../assets/icons/instagram.svg";
import { FcGoogle } from "react-icons/fc";
import { FaGithub, FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa";
import "./Slide1.css";

function Slide1() {
  return (
    <div>
      <div className="slide1">
        <a href="http://google.com" target="_blank" rel="noopener noreferrer">
          <Apps icon={<FcGoogle size={"2em"} />} name="Google" />
        </a>
        <a
          href="http://play.google.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apps
            icon={
              <img src={playstore} style={{ width: "2em" }} alt="playstore" />
            }
            name="Play Store"
          />
        </a>
        <a
          href="http://drive.google.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apps
            icon={<img src={drive} style={{ width: "4em" }} alt="drive" />}
            name="Google Drive"
          />
        </a>
        <a
          href="mailto:vatsal.rabadia@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apps
            icon={<img src={gmail} style={{ width: "2.2em" }} alt="gmail" />}
            name="Gmail"
          />
        </a>
        <a
          href="http://github.com/vats1812"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apps icon={<FaGithub color="black" size={"2em"} />} name="GitHub" />
        </a>
        <a
          href="http://www.instagram.com/_.vr._7432"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apps
            icon={
              <img src={instagram} style={{ width: "5em" }} alt="instagram" />
            }
            name="Instagram"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/vatsal-rabadia-182a3320b/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apps
            icon={<FaLinkedin color="0a66c2" size={"2.2em"} />}
            name="LinkedIn"
          />
        </a>
        <a
          href="https://www.facebook.com/vatsal.rabadia18/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apps
            icon={<FaFacebook color="420AFF" size={"2.1em"} />}
            name="Facebook"
          />
        </a>
        <a
          href="https://twitter.com/7432Vr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apps
            icon={<FaTwitter color="1DA1F2" size={"2em"} />}
            name="Twitter"
          />
        </a>
      </div>
    </div>
  );
}

export default Slide1;
