import React from "react";
import "@coreui/coreui/dist/css/coreui.min.css";
import {
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";
import { BsPlayCircleFill, BsCodeSlash } from "react-icons/bs";

function AccordionSlide2(props) {
  const { itemKey, name, icon, demolink, codelink } = props;

  return (
    <div>
      <CAccordionItem itemKey={itemKey}>
        <CAccordionHeader style={{ fontSize: "5rem" }}>
          {name} &nbsp; {icon}
        </CAccordionHeader>
        <CAccordionBody>
          {demolink === undefined || demolink === "" || demolink === null ? (
            ""
          ) : (
            <a
              href={demolink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="button code">
                <span> View Demo </span> <BsPlayCircleFill color="800000" />
              </button>
            </a>
          )}
          {codelink === undefined || codelink === "" || codelink === null ? (
            ""
          ) : (
            <a
              href={codelink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="button demo">
                <span>Get Code</span>
                <BsCodeSlash color="0080ff" />
              </button>
            </a>
          )}
        </CAccordionBody>
      </CAccordionItem>
    </div>
  );
}

export default AccordionSlide2;
