import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper.min.css";
import Footer from "../Footer/Footer";
import { Route, Routes } from "react-router";
import Call from "../Call/Call";
import Slide1 from "../Slides/Slide1/Slide1";
import Slide2 from "../Slides/Slide2/Slide2";
import Clocks from "../Slides/Slide2/Clock/Clocks";
import Tools from "../Slides/Slide2/Tools/Tools";
import Games from "../Slides/Slide2/Games/Games";
import Converters from "../Slides/Slide2/Converters/Converters";
import Javascript from "../Slides/Slide2/Javascript/Javascript";
import ReactProj from "../Slides/Slide2/ReactProj/ReactProj";
import Message from "../Message/Message";
import Clock from "react-live-clock";
import "./PhoneAppBody.css";
import Freelancing from "../Slides/Slide2/Freelancing/Freelancing";
SwiperCore.use([Pagination]);

function PhoneAppBody() {
  const pagination = {
    clickable: true,
  };

  return (
    <>
      <div className="appContainer">
        <div className="navBar">
          <Clock format={"HH:mm"} ticking={true} timezone={"Asia/Kolkata"} />
        </div>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Swiper slidesPerView={1} pagination={pagination}>
                  <SwiperSlide>
                    <Slide1 />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Slide2 />
                  </SwiperSlide>
                  {/* <SwiperSlide> Slide 3 </SwiperSlide>
                                                  <SwiperSlide> Slide 4 </SwiperSlide> */}
                </Swiper>
                <Footer />
              </>
            }
          />
          <Route exact path="/call/*" element={<Call />} />
          <Route exact path="/message" element={<Message />} />
          <Route exact path="/clock" element={<Clocks />} />
          <Route exact path="/tools" element={<Tools />} />
          <Route exact path="/games" element={<Games />} />
          <Route exact path="/converters" element={<Converters />} />
          <Route exact path="/javascript" element={<Javascript />} />
          <Route exact path="/react" element={<ReactProj />} />
          <Route exact path="/freelancing" element={<Freelancing />} />
        </Routes>
      </div>
    </>
  );
}

export default PhoneAppBody;
