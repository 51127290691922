import React from "react";
import { Route, Routes } from "react-router";
import Contacts from "../Contacts/Contacts";
import Keypad from "../Keypad/Keypad";
import Recents from "../Recents/Recents";

function CallBody() {
  return (
    <>
      <Routes>
        <Route index exact path="/keypad" element={<Keypad />} />
        <Route index exact path="/recents" element={<Recents />} />
        <Route index exact path="/contacts" element={<Contacts />} />
      </Routes>
    </>
  );
}

export default CallBody;
