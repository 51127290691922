import React from "react";
import {  FaReact } from "react-icons/fa";
import {
  FcAlarmClock,
  FcFilledFilter,
  FcMoneyTransfer,
  FcSupport,
} from "react-icons/fc";
import { SiJavascript } from "react-icons/si";
import { Link } from "react-router-dom";
import Apps from "../../Apps/Apps";
import game from "../../../assets/icons/game.svg";

function Slide2() {
  return (
    <div>
      <div className="slide2">
        <Link to="/clock">
          <Apps icon={<FcAlarmClock size={"2em"} />} name="Clock" />
        </Link>
        <Link to="/tools">
          <Apps icon={<FcSupport size={"2em"} />} name="Tools" />
        </Link>
        <Link to="/games">
          <Apps icon={<img src={game} alt="game" />} name="Games" />
        </Link>
        <Link to="/converters">
          <Apps icon={<FcFilledFilter size={"2em"} />} name="Converters" />
        </Link>
        <Link to="/javascript">
          <Apps
            icon={<SiJavascript color="#F0DB4F" size={"2em"} />}
            name="Javascript"
          />
        </Link>
        <Link to="/react">
          <Apps
            notify2="yes"
            icon={<FaReact color="#61DAFB" size={"2em"} />}
            name="React"
          />
        </Link>
        <Link to="/freelancing">
          <Apps
            notify2="yes"
            icon={<FcMoneyTransfer size={"2em"} />}
            name="Free-Lancing"
          />
        </Link>
      </div>
    </div>
  );
}

export default Slide2;
