import React from "react";
import { CAccordion } from "@coreui/react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { FcCurrencyExchange } from "react-icons/fc";
import "../Slide2.css";
import AccordionSlide2 from "../AccordionSlide2/AccordionSlide2";

function Converters() {
  return (
    <div>
      <div className="projectsCont convCont">
        <div className="projectsHeader convHeader">
          <Link to="/">
            <IoIosArrowBack size={"1.8em"} color="#0000FF" variant="Broken" />
          </Link>
          <h1>Converters</h1>
        </div>
        <div className="projectsBody convBody">
          <CAccordion>
            <AccordionSlide2
              itemKey={1}
              name="Currency Converter"
              icon={<FcCurrencyExchange size={"1.3em"} />}
              demolink="https://vats1812.github.io/vatspatel/currency%20converter/index.html"
              codelink="https://github.com/vats1812/vatspatel/tree/master/currency%20converter"
            />
          </CAccordion>
        </div>
      </div>
    </div>
  );
}

export default Converters;
