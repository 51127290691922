import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import "./CallHeader.css";

function CallHeader() {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/call/");
  let heading;
  if (splitLocation[1] === "keypad") {
    heading = "Keypad";
  } else if (splitLocation[1] === "recents") {
    heading = "Recents";
  } else if (splitLocation[1] === "contacts") {
    heading = "Contacts";
  }
  return (
    <>
      <div className="callHeader">
        <Link to="/">
          <IoIosArrowBack size={"1.8em"} color="#0000FF" variant="Broken" />
        </Link>{" "}
        <h1> {heading} </h1>{" "}
      </div>{" "}
    </>
  );
}

export default CallHeader;
