import React, { useRef } from "react";
import { FaUserSecret } from "react-icons/fa";
import {
  BsChatTextFill,
  BsTelephoneOutboundFill,
  BsCameraVideoFill,
} from "react-icons/bs";
import "./ContactsRaw.css";
function ContactsRaw({ names, active, onToggle }) {
  const contentEl = useRef();
  const { name, initial } = names;
  return (
    <>
      <div className="contactInitial">{initial}</div>
      <div className="contactCont">
        <div className="contactTitle" onClick={onToggle}>
          <div className="contactAvtar">
            <div className="avtaricon">
              <FaUserSecret color="black" size={"2em"} />
            </div>
          </div>
          <div className="contactName">{name}</div>
        </div>
        <div
          className="contactAction"
          ref={contentEl}
          style={
            active
              ? { height: "40px" }
              : { height: "0px" }
          }
        >
          <div className="icons call">
            <BsTelephoneOutboundFill color="white" onClick={()=>{alert("No SimCard Inserted!")}}/>
          </div>
          <div className="icons message">
            <BsChatTextFill color="white" onClick={()=>{alert("No SimCard Inserted!")}}/>
          </div>
          <div className="icons video">
            <BsCameraVideoFill color="white" onClick={()=>{alert("No SimCard Inserted!")}}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactsRaw;
