import React from "react";
import "./RecentsCall.css";
function RecentsCall(props) {
  return (
    <>
      <div className="recentDay">{props.recentDay} </div>
      <div className="recentscallCont">
        <div className="recentIcon">{props.recentIcon}</div>
        <div className="recentName">{props.recentName}</div>
        <div className="recentNumber">{props.recentNumber}</div>
        <div className="recentTime">{props.recentTime}</div>
      </div>
    </>
  );
}

export default RecentsCall;
