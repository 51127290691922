import { CAccordion } from "@coreui/react";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import AccordionSlide2 from "../AccordionSlide2/AccordionSlide2";
import { FaGlobe, FaHotel } from "react-icons/fa";

function Freelancing() {
  return (
    <>
      <div className="projectsCont  webCont">
        <div className="projectsHeader  webHeader">
          <Link to="/">
            <IoIosArrowBack size={"1.8em"} color="#0000FF" variant="Broken" />
          </Link>
          <h1>Free Lancing</h1>
        </div>
        <div className="projectsBody  webBody">
          <CAccordion>
            <AccordionSlide2
              itemKey={1}
              name="Maruti International"
              icon={<FaGlobe color="#000" size={"1em"} />}
              demolink="https://themarutiinternational.com/"
            />
            <AccordionSlide2
              itemKey={2}
              name="Van Vihar Resort"
              icon={<FaHotel color="#000" size={"1em"} />}
              demolink="https://vanviharresort.com/"
            />
          </CAccordion>
        </div>
      </div>
    </>
  );
}

export default Freelancing;
