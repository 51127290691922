import React from "react";
import { BsCircleFill } from "react-icons/bs";
import "./Apps.css";

function Apps(props) {
  return (
    <div>
      <div className="appMain">
        {props.notify === "yes" ? (
          <div className="appNoti">
            <BsCircleFill color="red" size={"2em"} />
          </div>
        ) : (
          ""
        )}
        {props.notify2 === "yes" ? (
          <div className="appNoti2">
            <BsCircleFill color="red" size={"2em"} />
          </div>
        ) : (
          ""
        )}
        <div className="appIcon"> {props.icon} </div>
        <div className="appName"> {props.name} </div>
      </div>
    </div>
  );
}

export default Apps;
