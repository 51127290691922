import "./App.css";
import PhoneBody from "./components/PhoneBody/PhoneBody";

function App() {
  return (
    <>
      <div className="body">
        <PhoneBody />
      </div>
    </>
  );
}

export default App;
