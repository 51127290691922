import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Timer, UserOctagon } from "iconsax-react";
import "./CallFooter.css";
function CallFooter() {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/call/");
  return (
    <>
      <div className="callFooter">
        <div className="callKeypad">
          <Link
            to="/call/keypad"
            style={{
              color: splitLocation[1] === "keypad" ? "#0000FF" : "#000000",
            }}
          >
            <Menu
              size="25"
              color={splitLocation[1] === "keypad" ? "#0000FF" : "#000000"}
              variant="Broken"
            />
            Keypad
          </Link>
        </div>
        <div className="callRecents">
          <Link
            to="/call/recents"
            style={{
              color: splitLocation[1] === "recents" ? "#0000FF" : "#000000",
            }}
          >
            <Timer
              size="25"
              color={splitLocation[1] === "recents" ? "#0000FF" : "#000000"}
              variant="Broken"
            />
            Recents
          </Link>
        </div>
        <div className="callContacts">
          <Link
            to="/call/contacts"
            style={{
              color: splitLocation[1] === "contacts" ? "#0000FF" : "#000000",
            }}
          >
            <UserOctagon
              size="25"
              color={splitLocation[1] === "contacts" ? "#0000FF" : "#000000"}
              variant="Broken"
            />
            Contacts
          </Link>
        </div>
      </div>
    </>
  );
}

export default CallFooter;
