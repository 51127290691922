import { React, useState } from "react";
import "./Keypad.css";
import { HiOutlineBackspace } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";

function Keypad() {
  const [value, setValue] = useState("");
  const handleKeyClick = (e) => {
    setValue(value.concat(e.target.value));
  };
  const handleDelete = () => {
    setValue(value.substring(0, value.length * 1 - 1));
  };
  const textChange = () => {
    console.log(value);
  };
  const handleCall = () => {
    alert("No SimCard Inserted!");
    setValue("");
  };
  return (
    <>
      <div className="keypadBody">
        <div className="keypadInput">
          <input
            type="tel"
            name="number"
            id="number"
            disabled
            value={value}
            onChange={textChange}
          />
        </div>
        <div className="keypadNumber">
          <div className="rowOne">
            <input type="button" value="1" onClick={handleKeyClick} />
            <input type="button" value="2" onClick={handleKeyClick} />
            <input type="button" value="3" onClick={handleKeyClick} />
          </div>
          <div className="rowTwo">
            <input type="button" value="4" onClick={handleKeyClick} />
            <input type="button" value="5" onClick={handleKeyClick} />
            <input type="button" value="6" onClick={handleKeyClick} />
          </div>
          <div className="rowThree">
            <input type="button" value="7" onClick={handleKeyClick} />
            <input type="button" value="8" onClick={handleKeyClick} />
            <input type="button" value="9" onClick={handleKeyClick} />
          </div>
          <div className="rowFour">
            <input type="button" value="+" onClick={handleKeyClick} />
            <input type="button" value="0" onClick={handleKeyClick} />
            <button onClick={handleDelete}>
              <HiOutlineBackspace size="32" color="#000000" />
            </button>
          </div>
        </div>
        <div className="keypadCall">
          <div className="callIcon">
            <button>
              <FiPhoneCall size="30" color="#000000" onClick={handleCall} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Keypad;
