import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import "./Message.css";
import MessageForm from "./MessageForm/MessageForm";
function Message() {
  return (
    <div>
      <div className="messageCont">
        <div className="messageHeader">
          <Link to="/">
            <IoIosArrowBack size={"1.8em"} color="#0000FF" variant="Broken" />
          </Link>
          <h1>Message</h1>
        </div>
        <div className="messageBody">
          <MessageForm />
        </div>
      </div>
    </div>
  );
}

export default Message;
